import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import { PositionShareModal } from "lynx-ui-components";
import { LynxLogo } from "../../Logos/LynxLogo.tsx";
import {
  useActiveLexStoreStore,
  useCryptoWalletIntegrationStore,
  useModalsStore,
  usePricesStore,
} from "../../../store/storeHooks.ts";
import { EMPTY_COMPLETE_POSITION_DATA_FROM_LENS } from "../../../services/contractsIntegration/TradingFloorLensService/ITradingFloorLensService.ts";
import { PAIR_ID_TO_NAME } from "../../../constants/pairsDisplayConstants.ts";
import { TPairIds } from "../../../constants/pairsConstants.ts";
import { priceBnToFloat } from "../../../utils/lynxScalesUtils.ts";
import { unitsBnToFloat } from "../../../utils/bignumbers.ts";
import { scaledLeverageToUnits } from "../../../utils/leverageCalculationsUtils.ts";
import { calculatePnlInfo } from "../../../utils/pnlCalculations.ts";
import { EMPTY_CLOSED_POSITION_GIST } from "../../../services/servicesIntergration/graphqlService/IGraphQLService.ts";
import { calculatePositionProfitFractionLikeContract } from "../../../hooks/tradeCalculations.ts";

interface IProps {
  open: boolean;
  closeModal: () => void;
}

export const PositionShareModalWrapper = observer<React.FC<IProps>>((props) => {
  const { open, closeModal } = props;

  const walletConnectionStore = useCryptoWalletIntegrationStore();
  const modalsStore = useModalsStore();
  const pricesStore = usePricesStore();
  const lexStoreStore = useActiveLexStoreStore();

  const positionId = modalsStore.positionShareModal_liveOrHistoricPositionId;
  const isLivePosition = modalsStore.positionShareModal_isLivePosition;

  const poolTokenDecimals = lexStoreStore?.poolTokenDecimals ?? 18;

  const rawAmountToUnits = useCallback(
    (rawAmount: bigint) => {
      return unitsBnToFloat(rawAmount, poolTokenDecimals);
    },
    [poolTokenDecimals],
  );

  const {
    pairId,
    isLong,
    entryPriceInUnits,
    liveCollateralInUnits,
    leverageInUnits,
    closePriceInUnits,
    interestFeeInUnits,
    fundingFeeInUnits,
    closingFeeInUnits,
  } = useMemo(() => {
    let pairId: TPairIds;
    let isLong: boolean;
    let entryPriceInUnits: number;
    let leverageInUnits: number;

    let closePriceInUnits: number;

    // NOTE : Currently not displayed
    let liveCollateralInUnits: number;

    // Fees paid
    let interestFeeInUnits: number;
    let fundingFeeInUnits: number;
    let closingFeeInUnits: number;

    if (isLivePosition) {
      const livePosition =
        lexStoreStore?.getLivePositionById(positionId) ??
        EMPTY_COMPLETE_POSITION_DATA_FROM_LENS;
      const closeFeeFInUnits =
        lexStoreStore?.getTradePairStoreById(Number(livePosition.pairId) ?? 0)
          ?.closeFeeFInUnits ?? 0;

      pairId = Number(livePosition.pairId) as TPairIds;
      isLong = livePosition.long;

      entryPriceInUnits = priceBnToFloat(livePosition.openPrice);
      liveCollateralInUnits = unitsBnToFloat(
        livePosition.collateral,
        poolTokenDecimals,
      );
      leverageInUnits = scaledLeverageToUnits(Number(livePosition.leverage));

      // No Close price
      closePriceInUnits = 0;

      interestFeeInUnits = rawAmountToUnits(livePosition.borrowInterest);
      fundingFeeInUnits = rawAmountToUnits(livePosition.funding);
      closingFeeInUnits =
        closeFeeFInUnits * leverageInUnits * liveCollateralInUnits;
    } else {
      const closedPosition =
        lexStoreStore?.getClosedPositionByGraphId(positionId) ??
        EMPTY_CLOSED_POSITION_GIST;

      pairId = closedPosition.pairId as TPairIds;
      isLong = closedPosition.long;
      entryPriceInUnits = priceBnToFloat(closedPosition.openPrice);
      liveCollateralInUnits = unitsBnToFloat(
        closedPosition.initialCollateral -
          closedPosition.openFeeForLexPool -
          closedPosition.openFeeForSystem,
        poolTokenDecimals,
      );
      leverageInUnits = closedPosition.leverageInUnits;
      closePriceInUnits = priceBnToFloat(closedPosition.closePrice);

      interestFeeInUnits = rawAmountToUnits(
        closedPosition.borrowInterestPaidOnClose,
      );
      fundingFeeInUnits = rawAmountToUnits(
        closedPosition.fundingFeesPaidOnClose,
      );
      closingFeeInUnits = rawAmountToUnits(closedPosition.closeFee);
    }

    return {
      pairId,
      isLong,
      entryPriceInUnits,
      liveCollateralInUnits,
      leverageInUnits,
      closePriceInUnits,
      interestFeeInUnits,
      fundingFeeInUnits,
      closingFeeInUnits,
    };
  }, [
    isLivePosition,
    lexStoreStore,
    poolTokenDecimals,
    positionId,
    rawAmountToUnits,
  ]);

  const assetSymbol = lexStoreStore?.sourceAssetParameters.symbol ?? "";

  // TODO : Find a better mechanism
  const pairInfo = PAIR_ID_TO_NAME[pairId] ?? "/";
  const symbolA = pairInfo.split("/")[0];
  const symbolB = pairInfo.split("/")[1];

  // console.log(`position.pairId ${position.pairId}`);
  // console.log(`pairInfo ${pairInfo}`);
  // console.log(`symbolA ${symbolA}`);
  // console.log(`symbolB ${symbolB}`);

  // const currentPrice = priceBnToFloat(lexStoreStore?.underlyingPrice ?? 0n);
  const relevantFinalPrice = isLivePosition
    ? pricesStore.getPriceForPairId(pairId)
    : closePriceInUnits;

  // const pnlInfo = calculatePnlInfo(
  //   collateralInUnits,
  //   leverageInUnits,
  //   entryPriceInUnits,
  //   relevantFinalPrice,
  //   isLong,
  //   interestFeeInUnits,
  //   fundingFeeInUnits,
  //   closingFeeInUnits,
  // );
  // const roiInPercentages = pnlInfo.roiInUnit * 100;

  const pnlInfo = calculatePnlInfo(
    liveCollateralInUnits,
    leverageInUnits,
    entryPriceInUnits,
    relevantFinalPrice,
    isLong,
    interestFeeInUnits,
    fundingFeeInUnits,
    0,
  );

  // const positionTradingProfitFraction =
  //   calculatePositionProfitFractionLikeContract(
  //     entryPriceInUnits,
  //     relevantFinalPrice,
  //     isLong,
  //     leverageInUnits,
  //   );
  // const roiInPercentages = positionTradingProfitFraction * 100;
  const roiInPercentages = pnlInfo.roiInUnit * 100;

  // TODO : C.F.H : Support Logos

  return (
    <PositionShareModal
      logoComponent={
        <div
          style={{
            height: "3rem",
            width: "5rem",
          }}
        >
          <LynxLogo />
        </div>
      }
      chainName={walletConnectionStore.chainName}
      backgroundImageUrl={"/assets/modals/sharingModalBackground.svg"}
      symbolA={symbolA}
      symbolB={symbolB}
      collateralAssetSymbol={assetSymbol}
      positionDirection={isLong ? "long" : "short"}
      entryPrice={entryPriceInUnits}
      closeOrCurrentPrice={relevantFinalPrice}
      isPositionClosed={!isLivePosition}
      roiInPercentages={roiInPercentages}
      leverageInUnits={leverageInUnits}
      open={open}
      closeModal={closeModal}
    />
  );
});

export default PositionShareModalWrapper;
