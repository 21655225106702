import { action, makeObservable, observable, runInAction } from "mobx";
import { CryptoWalletConnectionStore } from "./CryptoWalletConnectionStore";
import { TChainIds } from "../constants/chainConstants";
import { unitsBnToFloat } from "../utils/bignumbers.ts";
import { ethers } from "ethers";

export const NATIVE_FEE_FOR_REQUEST_OPEN = ethers.parseEther("0.8");

/**
 * Addresses for all system contracts deployed on a chain
 */
export type TSystemContracts = {
  tradingFloorProxyAddress: string;

  tradersPortalAddress: string;
  triggersAddress: string;

  chipIntentsVerifier: string;

  lexLensAddress: string;
  tradingCenterLens: string;
  intentsVerifierLens: string;
};

// TODO : Consider splitting some properties here to a standalone 'chainStore'
/**
 * Holds state of the Lynx Eco-System on a chain
 */
export class SystemStore {
  @observable public contractParametersDoneLoading = false;
  @observable public contractParametersErrorLoading = false;
  @observable public doneLoading = false;
  @observable public errorLoading = false;

  @observable public systemContractsMap = observable(
    new Map<TChainIds, TSystemContracts>(),
  );

  constructor(
    private cryptoWalletIntegrationStore: CryptoWalletConnectionStore,
    private systemContractsPerChain: Map<TChainIds, TSystemContracts>,
  ) {
    makeObservable(this);
    runInAction(() => {
      // We do not expect these values to change mid-flight
      this.systemContractsMap.replace(systemContractsPerChain);
    });
  }

  // **** Computed values ****

  // TODO : CRITICAL : Read these values from the TriggersAndPortalLens and make these observables

  @observable public readonly nativeFeeForRequestOpen =
    NATIVE_FEE_FOR_REQUEST_OPEN;
  @observable public readonly nativeFeeForRequestMarketClose = 1n;
  @observable public readonly nativeFeeForRequestUpdateSingleField = 1n;
  @observable public readonly nativeFeeForRequestUpdateDoubleField = 1n;

  // **** Contract interactions ****

  // **** Current address changed ****

  // ****  Complex setters ****

  // ****  Observables setter actions ****
  @action("setDoneLoading")
  private setDoneLoading(doneLoading: boolean) {
    this.doneLoading = doneLoading;
  }

  @action("setErrorLoading")
  private setErrorLoading(errorLoading: boolean) {
    this.errorLoading = errorLoading;
  }
}
